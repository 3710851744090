import {
  UPDATE_MASTER_DATA_CATEGORY,
} from '../def/actions';

const products = (state = {}, action) => {
  switch (action.type) {
    case UPDATE_MASTER_DATA_CATEGORY: {
      const { categories } = action;
      return { ...state, categories };
    }
    default:
      return state;
  }
};

export default products;

export const createMyInfo = `
  mutation {
    createMyInfo{
      CreatorID
    }
  }
`;

const responseProduct = `
Category
CreatedTime
CreatorID
Introduction {
  lang
  introduction
}
LastUpdate
Price
ProductID
Published
PublishedChangeDate
RentalPeriod
Thumbnail
Title{
  lang
  title
  titleKana
}
`;

export const createProduct = `
  mutation CreateProduct($CreateProductInput: CreateProductInput!) {
    createProduct(input: $CreateProductInput) {
      ${responseProduct}
    }
  }
`;

export const updateProduct = `
  mutation UpdateProduct($UpdateProductInput: UpdateProductInput!) {
    updateProduct(input: $UpdateProductInput) {
      ${responseProduct}
    }
  }
`;

export const deleteProduct = `
  mutation DeleteProduct($DeleteProductInput: DeleteProductInput!) {
    deleteProduct(input: $DeleteProductInput) {
      ${responseProduct}
    }
  }
`;

export const createProductDetail = `
  mutation CreateProductDetail($CreateProductDetailInput: CreateProductDetailInput!) {
    createProductDetail(input: $CreateProductDetailInput) {
      ProductID
      Questions{
        Answer
        Choices
        Explanation
        Question
        QuestionID
      }
    }
  }
`;

import {
  DELETE_PRODUCT,
  UPDATE_PRODUCTS,
  DELETE_PRODUCT_ITEM,
  UPDATE_PRODUCT_ITEM,
  PUBLISH_PRODUCT,
  UNPUBLISH_PRODUCT,
} from '../def/actions';

const REDUCERS = 'reducers';
const sortProducts = (products) => (
  products.sort((a, b) => {
    let ret = 0;
    // 公開＞非公開、公開日が直近、更新日が直近（後ろから判定している）
    if (a.LastUpdate < b.LastUpdate) ret = 1;
    if (a.LastUpdate > b.LastUpdate) ret = -1;
    if (a.PublishedChangeDate < b.PublishedChangeDate) ret = 1;
    if (a.PublishedChangeDate > b.PublishedChangeDate) ret = -1;
    if (!a.Published && b.Published) ret = 1;
    if (a.Published && !b.Published) ret = -1;
    return ret;
  })
);

const products = (state = [], action) => {
  switch (action.type) {
    case DELETE_PRODUCT: {
      console.log(REDUCERS, action.type, { state });
      const { deleteProductID } = action;
      const deletedProducts = state.filter((product) => deleteProductID !== product.ProductID);
      return sortProducts(deletedProducts);
    }
    case UPDATE_PRODUCTS: {
      console.log(REDUCERS, action.type, { state });
      const { updateProducts } = action;
      return sortProducts(updateProducts);
    }
    case PUBLISH_PRODUCT: {
      console.log(REDUCERS, action.type, { state });
      const { publishProduct } = action;
      const publishedProducts = state.map((product) => (
        publishProduct.ProductID === product.ProductID
          ? publishProduct
          : product
      ));
      return sortProducts(publishedProducts);
    }
    case UNPUBLISH_PRODUCT: {
      console.log(REDUCERS, action.type, { state });
      const { unpublishProduct } = action;
      const unpublishedProducts = state.map((product) => (
        unpublishProduct.ProductID === product.ProductID
          ? unpublishProduct
          : product
      ));
      return sortProducts(unpublishedProducts);
    }
    case DELETE_PRODUCT_ITEM: {
      console.log(REDUCERS, action.type, { state });
      const { items } = state;
      const { productID, delItemKey } = action;
      const deletedItems = items.filter((item, key) => delItemKey !== key);
      return { items: deletedItems, cnt: deletedItems.length };
    }
    case UPDATE_PRODUCT_ITEM: {
      console.log(REDUCERS, action.type, { state });
      const { newItem, updateKey } = action;
      const newItems = state.items.map((orgItem, key) => (updateKey === key ? newItem : orgItem));
      return { items: newItems, cnt: newItems.length };
    }
    default:
      return state;
  }
};

export default products;

import dayjs from 'dayjs';
import { DEFAULT_KANA_LANG, DEFAULT_ALPHABET_LANG } from '../def';

export const randomInt = (max) => Math.floor(Math.random() * max);
export const formatter = new Intl.NumberFormat('us-EN', {
  style: 'currency',
  currency: 'USD',
});
export const getDate = (dateStr = undefined) => dayjs(dateStr).format('DD MMM, YYYY');
export const createDate = (value, unit = 'day', dateStr = undefined) => dayjs(dateStr).add(value, unit).format('DD MMM, YYYY');
export const formatProductData = ({
  Title, Introduction, LastUpdate, CreatedTime,
}) => {
  const jpnTitle = Title ? Title[0].title : '';
  const jpnTitleKana = Title ? Title[0].titleKana : '';
  const jpnIntroduction = Introduction ? Introduction[0].introduction : '';
  const lastUpdate = LastUpdate ? getDate(LastUpdate * 1000) : '';
  const createdTime = CreatedTime ? getDate(CreatedTime * 1000) : '';
  return {
    jpnTitle, jpnTitleKana, jpnIntroduction, lastUpdate, createdTime,
  };
};
export const cutStr = (str, cnt) => (cnt < str.length ? `${str.slice(0, cnt - 1)}…` : str);
export const parceTag = (str) => {
  const splitTagReg = new RegExp(/<[^<>]*>/g);
  const separater = '{{ separater }}';

  // '<tagname>body</tagname>' => ['<tagname>', 'body', '</tagname'>] に変換
  const decomposedArr = str
    .replace(splitTagReg, (m) => (`${separater}${m}${separater}`))
    .split(separater)
    .filter((x) => !!x);

  // タグを組み立て
  const parcedArr = [];
  const tagCounts = decomposedArr.length / 3;
  for (let i = 0; i < tagCounts; i += 1) {
    // 3要素ずつ取り出し
    const pointer = i * 3;
    const openTag = decomposedArr[pointer];
    const body = decomposedArr[pointer + 1];
    const closeTag = decomposedArr[pointer + 2];

    // フォーマットをチェック
    const openTagName = openTag.match(/^<([^/<>]+)>$/)[1];
    const closeTagName = closeTag.match(/^<\/([^<>]+)>$/)[1];
    const isWellFormedBody = /^[^<>]+$/.test(body);
    const isSameTagName = openTagName && closeTagName && openTagName === closeTagName;
    if (isWellFormedBody && isSameTagName) {
      parcedArr.push({
        innerText: body,
        tagName: openTagName,
      });
    }
  }

  return parcedArr;
};

export const stringifyTag = (arr) => {
  const str = arr.map((tag) => {
    if (tag) {
      const { tagName, innerText } = tag;
      if (tagName && innerText && tagName !== '' && innerText !== '') {
        return `<${tag.tagName}>${tag.innerText}</${tag.tagName}>`;
      }
    }
    return null;
  }).filter((x) => !!x).join('');
  return str;
};

export const defLang = (txt) => {
  const regExp = /^(([a-z]+)([0-9!?.,！？]*))+$/gi;
  return regExp.test(txt) ? DEFAULT_ALPHABET_LANG : DEFAULT_KANA_LANG;
};

export const splitText = (txt) => (
  txt
    .split(/ |　/g) // eslint-disable-line no-irregular-whitespace
    .filter((x) => !!x)
    .map((x) => ({
      tagName: defLang(x),
      innerText: x,
    }))
);

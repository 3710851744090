import React from 'react';
import { SignUp } from 'aws-amplify-react';

import AccountBoxOutlinedIcon from '@material-ui/icons/AccountBoxOutlined';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import Container from '@material-ui/core/Container';
import CssBaseline from '@material-ui/core/CssBaseline';
import Grid from '@material-ui/core/Grid';
import Link from '@material-ui/core/Link';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';

import { EMAIL, PASSWORD } from '../../../def/interfaces';

const styles = (theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  submit: {
    margin: theme.spacing(2, 0, 3),
  },
});

class CustomSignUp extends SignUp {
  constructor(props) {
    super(props);

    // [必須] カスタムUIの時、どの項目を表示するか
    this.signUpFields = [
      {
        label: 'Username', // 変更不可
        key: 'email',
        required: true,
        displayrder: 1,
      },
      {
        label: 'Password', // 変更不可
        key: 'password',
        required: true,
        displayOrder: 2,
      },
    ];
  }

  showComponent() {
    const { classes } = this.props;
    return (
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <div className={classes.paper}>
          {/* Title */}
          <Avatar className={classes.avatar}>
            <AccountBoxOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            Sign up
          </Typography>

          {/* Email Address(Login ID) */}
          <TextField
            variant="outlined"
            margin="normal"
            autoFocus
            required
            fullWidth
            id={EMAIL.key}
            name={EMAIL.key}
            label={EMAIL.label}
            placeholder={EMAIL.label}
            autoComplete={EMAIL.key}
            onChange={this.handleInputChange}
          />

          {/* Password */}
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id={PASSWORD.key}
            name={PASSWORD.key}
            type={PASSWORD.key}
            label={PASSWORD.label}
            placeholder={PASSWORD.label}
            autoComplete="current-password"
            onChange={this.handleInputChange}
          />

          {/* SignUp Button */}
          <Button
            variant="contained"
            color="primary"
            size="large"
            fullWidth
            type="submit"
            className={classes.submit}
            onClick={() => super.signUp()}
          >
            Sign Up
          </Button>

          {/* Sign in */}
          <Grid container justify="flex-end">
            <Grid item>
              <Link href="#signin" variant="body2" onClick={() => super.changeState('signIn')}>
                Already have an account? Sign in
              </Link>
            </Grid>
          </Grid>
        </div>
      </Container>
    );
  }
}

export default withStyles(styles)(CustomSignUp);

import React from 'react';
import { ForgotPassword } from 'aws-amplify-react';

import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import Container from '@material-ui/core/Container';
import CssBaseline from '@material-ui/core/CssBaseline';
import Grid from '@material-ui/core/Grid';
import Link from '@material-ui/core/Link';
import SendIcon from '@material-ui/icons/Send';
import { withStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';

import { CODE, LOGIN_ID, NEW_PASSWORD } from '../../../def/interfaces';

const styles = (theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  submit: {
    margin: theme.spacing(2, 0, 3),
  },
});

class CustomForgotPassword extends ForgotPassword {
  showComponent() {
    const { authData = {}, classes } = this.props;
    return (
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <div className={classes.paper}>
          {/* Title */}
          <Avatar className={classes.avatar}>
            <SendIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            Reset your password
          </Typography>

          {/* Input Verify Code and Reset Password Mode */}
          {this.state.delivery || authData.username ? (
            <>
              {/* Verify Code */}
              <TextField
                variant="outlined"
                margin="normal"
                autoFocus
                required
                fullWidth
                id={CODE.key}
                label={CODE.label}
                placeholder={CODE.label}
                name={CODE.key}
                autoComplete="off"
                onChange={this.handleInputChange}
              />

              {/* NEW_PASSWORD */}
              <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                id={NEW_PASSWORD.key}
                label={NEW_PASSWORD.label}
                placeholder={NEW_PASSWORD.label}
                name={NEW_PASSWORD.key}
                type={NEW_PASSWORD.key}
                autoComplete="off"
                onChange={this.handleInputChange}
              />

              {/* Submit Code */}
              <Button
                variant="contained"
                size="large"
                color="primary"
                fullWidth
                type="submit"
                className={classes.submit}
                onClick={() => super.submit()}
              >
                Submit
              </Button>

              {/* Resend Code */}
              <Grid container justify="center">
                <Link href="#resend" variant="body2" onClick={() => super.send()}>
                  Resend Code
                </Link>
              </Grid>
            </>

          /* Send Verify Code Mode */
          ) : (
            <>
              {/* Login ID */}
              <TextField
                variant="outlined"
                margin="normal"
                autoFocus
                required
                fullWidth
                id={LOGIN_ID.key}
                label={LOGIN_ID.label}
                placeholder={LOGIN_ID.label}
                name={LOGIN_ID.key}
                autoComplete={LOGIN_ID.key}
                onChange={this.handleInputChange}
              />

              {/* Send Code */}
              <Button
                variant="contained"
                size="large"
                color="primary"
                fullWidth
                type="submit"
                className={classes.submit}
                onClick={() => super.send()}
              >
                Send Code
              </Button>

              {/* Back to Sign In */}
              <Grid container justify="center">
                <Link href="#signIn" variant="body2" onClick={() => super.changeState('signIn')}>
                  Back to Sign In
                </Link>
              </Grid>
            </>
          )}
        </div>
      </Container>
    );
  }
}

export default withStyles(styles)(CustomForgotPassword);

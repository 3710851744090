import React from 'react';

import CircularProgress from '@material-ui/core/CircularProgress';
import Container from '@material-ui/core/Container';
import CssBaseline from '@material-ui/core/CssBaseline';
import { makeStyles } from '@material-ui/core/styles';

import AppMenu from './AppMenu';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
  },
  container: {
    textAlign: 'center',
    height: 500,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
}));


const LazyLoading = () => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppMenu title="" />

      {/* メインコンテンツ */}
      <main className={classes.content}>
        <div className={classes.appBarSpacer} />
        <Container maxWidth="lg" className={classes.container}>
          <CircularProgress />
        </Container>
      </main>
    </div>
  );
};

export default LazyLoading;

export const getMyInfo = `
  query {
    getMyInfo{
      CreatorID
    }
  }
`;
export const listCategories = `
  query list($input: ListCategoriesInput){
    listCategories(input: $input){
      items{
        CategoryID
        CategoryName
      }
      nextToken
    }
  }
`;

export const getAlexaApp001Creators = `
  query ($CreatorID: String!) {
    getAlexaApp001Creators(CreatorID: $CreatorID){
      CreatorID
    }
  }
`;

const responseProduct = `
Category
CreatedTime
CreatorID
Introduction {
  lang
  introduction
}
LastUpdate
Price
ProductID
Published
PublishedChangeDate
RentalPeriod
Thumbnail
Title{
  lang
  title
  titleKana
}
`;

export const getProduct = `
  query getProduct($GetProductInput: GetProductInput!) {
    getProduct(input: $GetProductInput){
      ${responseProduct}
    }
  }
`;
export const getProductsByCreatorID = `
  query getProductsByCreatorID($GetProductsByCreatorIDInput: GetProductsByCreatorIDInput!) {
    getProductsByCreatorID(input: $GetProductsByCreatorIDInput){
      ${responseProduct}
    }
  }
`;
export const getProductDetail = `
  query getProductDetail($GetProductDetailInput: GetProductDetailInput!) {
    getProductDetail(input: $GetProductDetailInput){
      ProductID
      Questions{
        Answer
        Choices
        Explanation
        Question
        QuestionID
      }
    }
  }
`;
export const searchProducts = `
query searchProducts($filter: ListProductsFilterInput){
  listProducts(filter: $filter){
    items{
      Category
      CreatedTime
      CreatorID
      Introduction{
        lang
        introduction
      }
      LastUpdate
      Price
      ProductID
      Published
      PublishedChangeDate
      RentalPeriod
      Thumbnail
      Title{
        lang
        title
        titleKana
      }
    }
    nextToken
  }
}
`;
/*
{
  "filter": {
    "ProductID": {
      "contains": "ce8"
    }
  }
}
*/

import React from 'react';
import { SignIn } from 'aws-amplify-react';

import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import Container from '@material-ui/core/Container';
import { green } from '@material-ui/core/colors';
import CssBaseline from '@material-ui/core/CssBaseline';
import Grid from '@material-ui/core/Grid';
import Link from '@material-ui/core/Link';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import { withStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';

import { LOGIN_ID, PASSWORD } from '../../../def/interfaces';
import I18n from '../../../def/i18n';

const styles = (theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  submit: {
    margin: theme.spacing(1, 0, 3),
  },
  wrapper: {
    margin: theme.spacing(1),
    position: 'relative',
    width: '100%',
  },
  buttonSuccess: {
    backgroundColor: green[500],
    '&:hover': {
      backgroundColor: green[700],
    },
  },
  buttonProgress: {
    color: green[500],
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -18,
    marginLeft: -12,
  },
});

class CustomSignIn extends SignIn {
  constructor(props) {
    console.log('cons');
    super(props);
    this.state = {
      loading: false,
      success: false,
    };
    this.handleClickLoginButton = this.handleClickLoginButton.bind(this);
  }

  handleClickLoginButton() {
    const { loading } = this.state;
    if (!loading) {
      this.setState({
        loading: true,
      });
      this.signIn()
        .then(() => this.setState({ success: true }))
        .catch(() => this.setState({ success: false }))
        .finally(() => this.setState({ loading: false }));
    }
  }

  showComponent() {
    const { classes } = this.props;
    const { loading, success } = this.state;
    console.log({ loading, success });

    return (
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <div className={classes.paper}>
          {/* Title */}
          <Avatar className={classes.avatar}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            {I18n.get('Sign In')}
          </Typography>

          {/* Login ID */}
          <TextField
            variant="outlined"
            margin="normal"
            autoFocus
            required
            fullWidth
            id={LOGIN_ID.key}
            label={LOGIN_ID.label}
            placeholder={LOGIN_ID.label}
            name={LOGIN_ID.key}
            autoComplete={LOGIN_ID.key}
            onChange={this.handleInputChange}
          />

          {/* Password */}
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            name={PASSWORD.key}
            label={PASSWORD.label}
            placeholder={PASSWORD.label}
            type={PASSWORD.key}
            id={PASSWORD.key}
            autoComplete="current-password"
            onChange={this.handleInputChange}
          />

          {/* Login Button */}
          <div className={classes.wrapper}>
            <Button
              type="submit"
              size="large"
              fullWidth
              variant="contained"
              color="primary"
              disabled={loading}
              className={classes.submit}
              onClick={this.handleClickLoginButton}
            >
              {loading ? 'ログイン中' : I18n.get('Sign In')}
            </Button>
            {loading && <CircularProgress size={24} className={classes.buttonProgress} />}
          </div>

          {/* Forgot Password and Sign Up */}
          <Grid container>
            <Grid item xs>
              <Link href="#forgotPassword" variant="body2" onClick={() => super.changeState('forgotPassword')}>
                {I18n.get('Forget your password?')}
              </Link>
            </Grid>
            <Grid item>
              <Link href="#signUp" variant="body2" onClick={() => super.changeState('signUp')}>
                {I18n.get('Don\'t have an account? Sign Up')}
              </Link>
            </Grid>
          </Grid>
        </div>

        {/* DEBUG: */}
        <br />
        =&gt; testusertestuser@example.com TestPass
        {/* DEBUG: */}
      </Container>
    );
  }
}

export default withStyles(styles)(CustomSignIn);

import { combineReducers } from 'redux';

import myInfo from './myInfo';
import products from './products';
import masterData from './masterData';

export default combineReducers({
  myInfo,
  products,
  masterData,
});

import {
  UPDATE_MY_INFO,
} from '../def/actions';

const creator = (state = [], action) => {
  switch (action.type) {
    case UPDATE_MY_INFO: {
      const { myInfo } = action;
      return { ...state, ...myInfo };
    }
    default:
      return state;
  }
};

export default creator;

const amplifyConfig = {
  Auth: {
    identityPoolId: 'ap-northeast-1:c44cba3a-e9ba-418e-866a-b408dc2f9286',
    region: 'ap-northeast-1',
    userPoolId: 'ap-northeast-1_t9oezLFUY',
    userPoolWebClientId: '5m9b6peddrqu967g6sdd07bh2d',
  },
  aws_appsync_graphqlEndpoint: 'https://hcmugw6pzzdd7cju36cryowmsu.appsync-api.ap-northeast-1.amazonaws.com/graphql',
  aws_appsync_region: 'ap-northeast-1',
  aws_appsync_authenticationType: 'AMAZON_COGNITO_USER_POOLS',
};
export default amplifyConfig;

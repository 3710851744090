import React, {
  lazy,
  Suspense,
  useEffect,
  useReducer,
} from 'react';
import { // SPA用
  BrowserRouter as Router,
  Route,
  Switch,
} from 'react-router-dom';

// 認証基盤
import Amplify from '@aws-amplify/core';
import API, { graphqlOperation } from '@aws-amplify/api';

import {
  ConfirmSignIn,
  ConfirmSignUp,
  RequireNewPassword,
  withAuthenticator,
} from 'aws-amplify-react';
import amplifyConfig from '../config/aws-exports';

// 認証用画面（カスタマイズ）
import CustomSignIn from './common/amplify-auth-custom/SignIn';
import CustomSignUp from './common/amplify-auth-custom/SignUp';
import CustomForgotPassword from './common/amplify-auth-custom/ForgotPassword';
import CustomVerifyContact from './common/amplify-auth-custom/VerifyContact'; // メール未認証のログイン時

// デザイン要素 // TODO: なくす
import '../assets/css/App.scss';

// 利用するAPI
import { listCategories, getMyInfo } from '../graphql/queries';
import { createMyInfo } from '../graphql/mutations';

// state管理 // TODO: 空
import AppContext from '../contexts/AppContext'; // AppContextによるApp全体でのstate利用
import reducer from '../reducers'; // App全体でのstate更新処理を切り出し
import { UPDATE_MASTER_DATA_CATEGORY, UPDATE_MY_INFO } from '../def/actions';

// 画面部品
import LazyLoading from './common/LazyLoading';

const Dashboard = lazy(() => import('./Dashboard'));
const TestList = lazy(() => import('./TestList'));
const CreateTest = lazy(() => import('./CreateTest'));
const CreateTestItems = lazy(() => import('./CreateTestItems'));
const ViewTest = lazy(() => import('./ViewTest'));
const Error = lazy(() => import('./Error'));

Amplify.configure(amplifyConfig);

function App() {
  // App全体でのstateの初期値
  const initialState = {
    myInfo: {},
    products: [],
  };
  // App全体でのstate利用準備
  const [state, dispatch] = useReducer(reducer, initialState);
  console.log('App', { state });

  // 最初に1回呼ばれる処理
  useEffect(() => {
    let isCleanUp = false;
    console.log('App', 'initial useEffect.');
    // initialize
    const getCreators = async () => {
      // ユーザ情報を取得
      const { data } = await API.graphql(graphqlOperation(getMyInfo));
      let myInfo = data.getMyInfo;

      // ユーザ情報が取得できなかった場合は新規登録
      if (!myInfo) {
        console.log('App', 'Create User.');
        // eslint-disable-next-line no-shadow
        const { data } = await API.graphql(graphqlOperation(createMyInfo));
        myInfo = data.createMyInfo;
      }

      // stateを更新
      console.log('App', 'myInfo', myInfo);
      if (!isCleanUp) {
        dispatch({
          type: UPDATE_MY_INFO,
          myInfo,
        });
      }
    };
    // masterデータの取得
    const getCategory = async () => {
      const { data } = await API.graphql(graphqlOperation(listCategories));
      const categories = data.listCategories;
      if (!isCleanUp) {
        dispatch({
          type: UPDATE_MASTER_DATA_CATEGORY,
          categories: categories.items,
        });
      }
    };

    getCreators();
    getCategory();
    // localStorage.clear();
    return () => {
      isCleanUp = true;
    };
  }, [dispatch]);

  return (
    <AppContext.Provider value={{ state, dispatch }}>
      <Router>
        {/* loading */}
        <Suspense fallback={LazyLoading()}>
          {/* メインコンテンツ */}
          <Switch>
            <Route exact path="/" component={Dashboard} />
            <Route exact path="/testlist" component={TestList} />
            <Route exact path="/createtest" component={CreateTest} />
            <Route exact path="/createtest/:productID/items" component={CreateTestItems} />
            <Route exact path="/test/loading" component={LazyLoading} />
            <Route exact path="/viewtest/:productID" component={ViewTest} />
            <Route component={Error} />
          </Switch>
          {/* メインコンテンツ */}
        </Suspense>
      </Router>
    </AppContext.Provider>
  );
}

/*
Amplifyの認証基盤をベースに見た目をカスタマイズして、Appに登録
withAuthenticator(
  Comp,
  includeGreetings = false,
  authenticatorComponents = [],
  federated = null,
  theme = null,
  signUpConfig = {}
)
*/
const includeGreetings = false; // falseにしないとカスタムコンポーネントが効かない
const authenticatorComponents = [
  <CustomSignIn />,
  <CustomSignUp />,
  <CustomForgotPassword />,
  <CustomVerifyContact />,
  <ConfirmSignIn />,
  <ConfirmSignUp />,
  <RequireNewPassword />,
];
const federated = null;
const theme = null;
const signUpConfig = {};
export default withAuthenticator(
  App,
  includeGreetings,
  authenticatorComponents,
  federated,
  theme,
  signUpConfig,
);

import React from 'react';
import { VerifyContact } from 'aws-amplify-react';

import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import Container from '@material-ui/core/Container';
import CssBaseline from '@material-ui/core/CssBaseline';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Radio from '@material-ui/core/Radio';
import SendIcon from '@material-ui/icons/Send';
import { withStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';

import { CODE } from '../../../def/interfaces';

const styles = (theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  submit: {
    margin: theme.spacing(2, 0, 3),
  },
});

class CustomVerifyContact extends VerifyContact {
  showComponent() {
    const { classes } = this.props;
    return (
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <div className={classes.paper}>
          {/* Title */}
          <Avatar className={classes.avatar}>
            <SendIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            {/* I18n.get('Account recovery requires verified contact information') */}
            Account recovery requires verified contact information
          </Typography>

          {/* Input Verify Code and Resend Code */}
          {this.state.verifyAttr ? (
            /* Input resended verify Code */
            <>
              <TextField
                variant="outlined"
                margin="normal"
                autoFocus
                required
                fullWidth
                id={CODE.key}
                label={CODE.label}
                placeholder={CODE.label}
                name={CODE.key}
                autoComplete="off"
                onChange={this.handleInputChange}
              />
              <Button
                type="submit"
                size="large"
                fullWidth
                variant="contained"
                color="primary"
                className={classes.submit}
                onClick={() => this.submit()}
              >
                Submit
              </Button>
            </>
          ) : (
            /* Resend verify Code */
            <>
              <FormControlLabel
                value="contact"
                control={(
                  <Radio
                    onChange={this.handleInputChange}
                    value="email"
                    name="contact"
                    label="email"
                    placeholder="email"
                    id="_email"
                  />
                )}
                label="Email"
              />
              <Button
                type="submit"
                size="large"
                fullWidth
                variant="contained"
                color="primary"
                className={classes.submit}
                onClick={() => this.verify()}
              >
                Verify
              </Button>
            </>
          )}
        </div>
      </Container>
    );
  }
}

export default withStyles(styles)(CustomVerifyContact);

export const CODE = {
  key: 'code',
  label: 'Code',
};
export const EMAIL = {
  key: 'email',
  label: 'Email Address',
};
export const LOGIN_ID = {
  key: 'username',
  label: 'Email Address',
};
export const NEW_PASSWORD = {
  key: 'password',
  label: 'New Password',
};
export const PASSWORD = {
  key: 'password',
  label: 'Password',
};

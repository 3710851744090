export const UPDATE_MY_INFO = 'UPDATE_MY_INFO';

export const DELETE_PRODUCT = 'DELETE_PRODUCT';
export const CREATE_PRODUCT = 'CREATE_PRODUCT';
export const UPDATE_PRODUCTS = 'UPDATE_PRODUCTS';

export const DELETE_PRODUCT_ITEM = 'DELETE_PRODUCT_ITEM';
export const UPDATE_PRODUCT_ITEM = 'UPDATE_PRODUCT_ITEM';

export const UPDATE_MASTER_DATA_CATEGORY = 'UPDATE_MASTER_DATA_CATEGORY';

export const PUBLISH_PRODUCT = 'PUBLISH_PRODUCT';
export const UNPUBLISH_PRODUCT = 'UNPUBLISH_PRODUCT';

export const INITIALIZE_QUESTIONS = 'INITIALIZE_TEST';
export const ADD_QUESTION = 'ADD_QUESTION';
export const DELETE_QUESTION = 'DELETE_QUESTION';
export const UPDATE_QUESTION = 'UPDATE_QUESTION';
export const UPDATE_QUESTION_TEXT = 'UPDATE_QUESTION_TEXT';
export const CALCULATE_QUESTION_OBJ = 'CALCULATE_QUESTION_OBJ';
export const CHANGE_QUESTION_WORD_LANG = 'CHANGE_QUESTION_WORD_LANG';
export const UPDATE_CHOICES_TEXT = 'UPDATE_CHOICES_TEXT';
export const CALCULATE_CHOICES_OBJ = 'CALCULATE_CHOICES_OBJ';
export const CHANGE_CHOICES_WORD_LANG = 'CHANGE_CHOICES_WORD_LANG';
export const UPDATE_ANSWER_TEXT = 'UPDATE_ANSWER_TEXT';
